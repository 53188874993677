/*Entire Container for the card*/
.b_card-container{
    /*border: solid white 3px;*/
    width: 200px;
    height: 550px;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    
    /* 3D Effects*/
    perspective: 800px;
    tab-index: 0;
}

/*Profile Picture
==========================================================================
*/

.profile-pic{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    max-width: 200px;
}

/*Contact Info Box
==========================================================================
*/
.contact-info{
    /*border: solid red 1px;*/
    /*padding-top: 5px;*/
    display: flex;
    flex-flow: column;
    align-content: flex-start;
    /*height: 80px;*/
}

.contact-name{
    color: lightgrey;
    display: grid;
    justify-content: center;
    /*border: solid white 1px;*/
    font-size: 24px;
    font-family: Garamond, serif bold;
    margin-bottom: 5px;
}
.contact-title{
    color: #d6b856;
    display: grid;
    justify-content: center;
    /*border: solid white 1px;*/
    font-size: 13px;
    /*margin-top: -3px;*/
}
.contact-website{
    color: lightgrey;
    display: grid;
    justify-content: center;
    /*border: solid white 1px;*/
    font-size: 12px;
}

/*Email Box
==========================================================================
*/
.email-container{
    /*border: solid white 1px;*/
    display: flex;
    flex-direction: row;
    justify-content:center;
    /*align-content: flex-start;*/
    color: black;
    background-color: white;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 10px;
    border-radius: 3px;
    height: 20px;
    cursor: pointer;
}

.email-icon{
    scale: 80%;
}

.email-text{
    display: flex;
    font-size: 10px;
    font-family: Verdana, sans-serif;
    /*border: solid red 2px;*/
    align-items: center;
}

/*About Me and Interests Box
==========================================================================
*/
.interests-container,
.about-container{
    /*border: solid white 1px;*/
    margin: 10px;
    height: 108px;
    max-width: 100%;
    border-radius: 6px;
    display: flex;
    flex-direction: column;

}

.interests-title,
.about-title{

    font-size: 12px;
    color: #d6b856;
}

.interests-text,
.about-text{
    font-size: 12px;
    color: white;
}

/*Card footer
==========================================================================
*/
.card-footer,
.card-footer-text{
    display: none;
}

@media only screen and (max-width: 480px) {

    .card-footer {
        display: grid;
        justify-content: center;
        /*border: solid white 1px;*/
    }

    .card-footer-text {
        display: block;
        color: white;
        font-size: 12px;

    }
}
/* 3D Effects on hover for Business card
===================================================================
*/
.card{
    height: 100%;
    width: 100%;
    position: relative;
    transition: transform 900ms;
    transform-style: preserve-3d;
    /*border: solid white 1px;*/
}

.b_card-container:hover  > .card{
    cursor: pointer;
    transform: rotateY(180deg) scale(1.15);
}

.card-front{
    /* See b_card-container*/
    background-color: #212729;

}

.card-back{
    color: white;
    background-color: #212729;
    /*border: solid green 1px;*/
    word-wrap: break-word;
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
}

.card-front,
.card-back{
    height: 100%;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: absolute;
    backface-visibility: hidden;
}
.card-back-text,
.card-back-header{
    display: flex;
    justify-content: center;
    /*border: solid red 1px;*/
}

