html,body{
    /*overflow: auto;*/
    min-width: fit-content;
}
.risk-html{
    background-color: #f0f2f5;
    /*height: 100vh;*/
    /*overflow: auto;*/

}
.risk-header{
    background-color: lightskyblue;
    border-top: solid black 2px;
    border-bottom: solid black 2px;
}
/*removes the empty space above the header*/
.risk-header > h1{
    padding-left: 20px;
    display:table-cell;
    vertical-align:middle;
    padding-top: 5px;
    padding-bottom: 5px;
}
.risk-container{
    background-color: #f0f2f5;
    display: flex;
    gap: 20px;
    padding: 20px;
    /*overflow: scroll;*/

}

.survivor-selection{
    background-color: #b5b5b5;
    border: solid black 2px;
    /*border-radius: 1em;*/
    max-width: 800px;
    min-width: 500px;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: 45% 55%;

}

.survivor-panel-container{
    background-color: #b5b5b5;
    border: solid black 2px;
    /*border-radius: 1em;*/
}

.survivors{
    display: grid;
    gap: .5rem;
    grid-template-columns: repeat(2, 1fr);
    padding: .5rem;
    justify-items: center;
}

.survivors img:last-child{
    grid-column: span 2;
}
.survivor-icon{
    width: 75px;
    height: 75px;
    border-radius:50%;
}
.randomize-btn-container{
    display: grid;
    justify-content: center;
    align-content: center;
}

.randomize-btn{
    font-size: 18px;
    width: fit-content;
    margin: .5em;
    background-color: lightskyblue;
    border-radius: 8px;

}

.risk-footer{
    background-color: lightgrey;
    /*position: absolute;*/
    /*bottom: 0;*/
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.survivor-pfp-panel{
    display: grid;
    align-items: center;
    justify-content: center;
    /*width: 220px;*/
    padding-left: 20px;
}
.risk-pfp{
    width: 160px;
    border-radius: 5px;
    transform: scaleX(-1);
}

.loadout{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-top: solid black 2px;
    padding-left: 10px;
}

.span-3{
    grid-column: span 3;
}
.span-2{
    grid-column: span 2;
}
.span-4{
    grid-column: span 4;
}
.loadout img{
    width: 80px;
}

.loadout-spacing{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;
    min-width: 230px;
}
.stats{
    display: flex;
    flex-direction: column;
    font-size: 16px;
    align-content: flex-start;
    justify-content: center;
    min-width: 400px;

}
.border{
    outline: solid red 5px;
}

.center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.name{
    font-size: 26px;
}

